<template lang="pug">
  modal.setting-selector(ref="modal", :width="width", transition="modal", :max-width="800", :name="modalName", :adaptive="true", height="auto", @closed="enableScrolling")
    .modal-header
      .close-button.is-flex(@click="onCloseHandler")
        span {{ $t('common.close') }}
        icon(iconName="close", iconColor="#2e2e2e")
    .modal-body.is-flex
      .selector-title.title.is-italic {{ $t('detailsPage.mainDetails.settingTypePickerSale.title') }}
      .settings-images.is-flex
        .image-wrapper
          v-image.image(@click="onSettingsClick('ringsList')", :class="{selected: selectedType === 'ringsList'}", src="details/ring@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.ringsList') }}
        .image-wrapper
          v-image.image(@click="onSettingsClick('earringsList')", :class="{selected: selectedType === 'earringsList'}", src="details/earring@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.earringsList') }}
        .image-wrapper
          v-image.image(@click="onSettingsClick('braceletsList')", :class="{selected: selectedType === 'braceletsList'}", src="details/bracelets@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.braceletsList') }}
        .image-wrapper
          v-image.image(@click="onSettingsClick('necklacesList')", :class="{selected: selectedType === 'necklacesList'}", src="details/necklaces@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.necklacesPendantsList') }}
      button.continue-button.button.primary-color(@click="onContinueClick", :disabled="!selectedType") {{ $t('detailsPage.mainDetails.settingTypePicker.continue') }}
</template>

<script>
import SettingSelector from '~~/components/DetailsPage/DetailsMain/SettingsSelector'

const routesMap = {
  ruby: {
    ringsList: 'ruby-jewelry-ruby-rings',
    earringsList: 'ruby-jewelry-ruby-earrings',
    default: 'ruby-jewelry-ruby-necklaces-pendants',
    braceletsList: 'ruby-jewelry-ruby-bracelets'
  },
  emerald: {
    ringsList: 'emerald-jewelry-emerald-rings',
    earringsList: 'emerald-jewelry-emerald-earrings',
    default: 'emerald-jewelry-emerald-necklaces-pendants',
    braceletsList: 'emerald-jewelry-emerald-bracelets'
  },
  gemstone: {
    ringsList: 'all-gemstone-rings',
    earringsList: 'gemstone-earrings',
    default: 'gemstone-necklaces-pendants',
    braceletsList: 'gemstone-bracelets'
  },
  sapphire: {
    ringsList: 'sapphire-rings',
    earringsList: 'sapphire-earrings',
    default: 'sapphire-necklaces-pendants',
    braceletsList: 'sapphire-bracelets'
  }
}

export default {
  name: 'SettingSelectorSaleComponent',

  extends: SettingSelector,

  data: () => ({
    modalEvent: 'settings-selector-sale',
    modalName: 'settings-selector-sale'
  }),

  methods: {
    onContinueClick() {
      this.onCloseHandler()
      this.$router.push(this.localePath(this.getListingRoute()))
    },

    getListingRoute() {
      const routeConfig = {
        name:
          routesMap[this.$site.name][this.selectedType] ||
          routesMap[this.$site.name].default
      }

      routeConfig.query = { availability: 1 }

      // if (this.$site.name !== 'gemstone') {
      //   routeConfig.query = { availability: 1 }
      // }

      return routeConfig
    }
  }
}
</script>
