<template lang="pug">
  modal.setting-selector(ref="modal", transition="modal", :width="width", :max-width="800", :name="modalName", :adaptive="true", height="auto", @closed="enableScrolling")
    .modal-header
      .close-button.is-flex(@click="onCloseHandler")
        span {{ $t('common.close') }}
        icon(iconName="close", iconColor="#2e2e2e")
    .modal-body.is-flex
      .selector-title.title.is-italic {{ $t('detailsPage.mainDetails.settingTypePicker.title') }}
      .settings-images.is-flex
        .image-wrapper
          v-image.image(@click="onSettingsClick('ringsList')", :class="{selected: selectedType === 'ringsList'}", src="details/ring@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.ringsList') }}
        .image-wrapper
          v-image.image(@click="onSettingsClick('necklacesList')", :class="{selected: selectedType === 'necklacesList'}", src="details/necklaces@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.necklacesPendantsList') }}
        .image-wrapper
          v-image.image(@click="onSettingsClick('braceletsList')", :class="{selected: selectedType === 'braceletsList'}", src="details/bracelets@2x.jpg")
          .image-label {{ $t('detailsPage.mainDetails.settingTypePicker.braceletsList') }}
      button.continue-button.button.primary-color(@click="onContinueClick", :disabled="!selectedType") {{ $t('detailsPage.mainDetails.settingTypePicker.continue') }}
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import modalMixin from '~~/mixins/modalMixin'
import { CustomNextPath, flushQuery } from '~~/utils/utils'

export default {
  name: 'SettingSelectorComponent',

  mixins: [modalMixin],

  inject: ['pageType'],

  data: () => ({
    modalEvent: 'settings-selector',
    modalName: 'settings-selector',
    selectedType: ''
  }),

  computed: {
    ...mapGetters({
      customStone: 'customItem/customStone',
      customSetting: 'customItem/customSetting'
    })
  },

  mounted() {
    this.$emit('mounted')
    if (this.$device.isMobile) {
      this.width = '100%'
      return
    }
    this.width = '50%'
  },

  methods: {
    ...mapActions({
      enablePrefilter: 'filters/enablePrefilter',
      dropNecklaceItems: 'necklaceSettings/dropItems',
      dropPendantItems: 'pendantSettings/dropItems',
      dropRingItems: 'ringSettings/dropItems',
      dropEarringItems: 'earringSettings/dropItems',
      dropBraceletItems: 'braceletSettings/dropItems'
    }),

    dropSettingsItems() {
      this.dropNecklaceItems()
      this.dropPendantItems()
      this.dropRingItems()
      this.dropEarringItems()
      this.dropBraceletItems()
    },

    onContinueClick() {
      this.dropSettingsItems()
      this.$modal.hide(this.modalName)
      const route = new CustomNextPath({
        customStone: this.customStone,
        customSetting: this.customSetting,
        route: this.$route,
        type: this.selectedType,
        query: flushQuery(this.$route.query)
      })
      this.enablePrefilter()
      this.$router.push(this.localePath(route.get()))
    },

    onSettingsClick(type) {
      this.selectedType = type
    }
  }
}
</script>

<style lang="sass" scoped>
.setting-selector
  z-index: 10000 !important
  overflow-y: auto
  ::v-deep
    .v--modal-box
      +tablet-only
        width: 80% !important
        margin: auto !important
        left: initial !important
      +mobile
        width: 100% !important
        left: 0 !important

.modal-header
  position: relative
  height: 30px
  .close-button
    cursor: pointer
    position: absolute
    top: 10px
    right: 10px
    align-items: center
    text-transform: uppercase
    svg
      margin-left: 5px
      height: 15px
      width: 15px
.modal-body
  padding: 20px
  flex-direction: column
  align-items: center
  .settings-images
    +mobile
      flex-wrap: wrap
      justify-content: space-between
    .image-wrapper
      max-width: 138px
      width: 100%
      +mobile
        max-width: 45%
      .image
        border: 1px solid $border-color
        cursor: pointer
        &.selected
          border: 1px solid $dark-primary-color
        &-label
          text-align: center
          font-weight: 700
          text-transform: uppercase
    .image-wrapper + .image-wrapper
      margin-left: 20px
      +mobile
        margin-left: unset
        margin-bottom: 20px
  .continue-button
    margin-top: 20px
    width: 200px
</style>
